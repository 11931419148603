import { isError404 } from 'common/utils/helper';
import { microsoftApi } from 'app/services/microsoft';
import { solutionApi } from 'app/services/solution';
import { useAppSelector } from 'app/hooks';
import { useParams } from 'react-router-dom';

const useGlobalIsError = () => {
  const { depotId } = useParams();

  return useAppSelector((state) =>
    [solutionApi, microsoftApi].some(
      (api) =>
        Object.values(state[api.reducerPath]?.queries || {}).some((query) => {
          // RTK Query does an unwanted getDepot API call after deleting the depot
          const suppressErrorAfterDeletingDepot = query?.endpointName === 'getDepot' && !depotId;

          if (suppressErrorAfterDeletingDepot || isError404(query?.error)) {
            return false;
          }

          return query?.error;
        }) ||
        Object.values(state[api.reducerPath]?.mutations || {}).some((mutation) => {
          return mutation?.error;
        }),
    ),
  );
};

export default useGlobalIsError;
