import { globalLoadingEnabledSelector } from '../globalLoadingSlice';
import { microsoftApi } from 'app/services/microsoft';
import { solutionApi } from 'app/services/solution';
import { useAppSelector } from 'app/hooks';

const useGlobalIsLoading = () => {
  const globalLoadingEnabled = useAppSelector(globalLoadingEnabledSelector);

  const isPending = useAppSelector((state) => {
    const checkPending = (api: typeof solutionApi | typeof microsoftApi) =>
      Object.values(state[api.reducerPath]?.queries || {}).some((query) => {
        return query?.status === 'pending';
      }) ||
      Object.values(state[api.reducerPath]?.mutations || {}).some((mutation) => {
        return mutation?.status === 'pending';
      });

    return checkPending(solutionApi) || checkPending(microsoftApi);
  });

  return globalLoadingEnabled && isPending;
};

export default useGlobalIsLoading;
