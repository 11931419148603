import GlobalLoading from 'features/globalLoading/GlobalLoading';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { APP_TITLE, APP_USP } from 'app/config';
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';
import { defineCustomElements } from '@scania/tegel-react';
import { ElementControllerProvider } from 'app/context/ElementControllerContext';
import { enableMocking } from './mocks/setup';
import { ExpandVehicleProvider } from 'app/context/ExpandVehicleAndTabContext';
import { Helmet } from 'react-helmet';
import { Loading, ToastProvider, UpdateApp } from '@optimization/ssi-common';
import { msalConfig } from 'app/config/auth';
import { MsalProvider } from '@azure/msal-react';
import { Provider } from 'react-redux';
import { PublicClientApplication } from '@azure/msal-browser';
import { StickyHandlerProvider } from 'app/context/StickyHandlerContext';
import { store } from 'app/store';
import './index.scss';

defineCustomElements();

export const msalInstance = new PublicClientApplication(msalConfig);

const Datadog = React.lazy(() => import('features/datadog'));
const DepotDetail = React.lazy(() => import('features/infrastructure/DepotDetail'));
const Header = React.lazy(() => import('features/navigation/Header'));
const Layout = React.lazy(() => import('common/components/Layout'));
const LoadUser = React.lazy(() => import('features/auth/LoadUser'));
const PageNotFound = React.lazy(() => import('common/components/PageNotFound'));
const RouteError = React.lazy(() => import('common/components/RouteError'));
const SideNavbarSolutionPresentation = React.lazy(() => import('features/navigation/SideNavbar/SolutionPresentation'));
const SolutionManager = React.lazy(() => import('features/solution/SolutionManager'));
const SolutionPresentationHome = React.lazy(() => import('features/presentation/Home'));
const SolutionPresentationPerformanceSteps = React.lazy(() => import('features/presentation/PerformanceSteps'));
const NextGeneration = React.lazy(() => import('features/presentation/NextGeneration'));
const SolutionPresentationProductShowcase = React.lazy(() => import('features/presentation/ProductShowcase'));
const SolutionPresentationStepDetail = React.lazy(() => import('features/presentation/PerformanceStepDetail'));

export const routes = {
  errorElement: (
    <Suspense fallback={<Loading isLoading />}>
      <RouteError />
    </Suspense>
  ),
  element: (
    <ElementControllerProvider>
      <Helmet>
        <title>
          {APP_TITLE} - {APP_USP}
        </title>
      </Helmet>
      <GlobalLoading />
      <Suspense fallback={<Loading isLoading />}>
        <LoadUser>
          <Datadog>
            <UpdateApp appTitle={APP_TITLE}>
              <ExpandVehicleProvider>
                <StickyHandlerProvider>
                  <Header />
                  <Layout>
                    <Outlet />
                  </Layout>
                </StickyHandlerProvider>
              </ExpandVehicleProvider>
            </UpdateApp>
          </Datadog>
        </LoadUser>
      </Suspense>
    </ElementControllerProvider>
  ),
  children: [
    {
      path: '/',
      element: <SolutionManager />,
    },
    {
      element: (
        <SideNavbarSolutionPresentation>
          <Outlet />
        </SideNavbarSolutionPresentation>
      ),
      children: [
        {
          element: <SolutionPresentationHome />,
          errorElement: <RouteError />,
          path: '/solution/:solutionId/presentation/home',
        },
        {
          element: <SolutionPresentationProductShowcase />,
          errorElement: <RouteError />,
          path: '/solution/:solutionId/presentation/product-showcase',
        },
        {
          element: <SolutionPresentationPerformanceSteps />,
          errorElement: <RouteError />,
          path: '/solution/:solutionId/presentation/performance-steps',
        },
        {
          element: <NextGeneration />,
          errorElement: <RouteError />,
          path: '/solution/:solutionId/presentation/performance-steps/next-generation/:chassisAdaptionParam',
        },
        {
          element: <SolutionPresentationStepDetail />,
          errorElement: <RouteError />,
          path: '/solution/:solutionId/presentation/performance-steps/:yearParam/:entityType',
        },
        {
          element: <DepotDetail />,
          errorElement: <RouteError />,
          path: '/solution/:solutionId/presentation/performance-steps/:yearParam/:entityType/:depotId',
        },
      ],
    },
    {
      path: 'callback',
    },
    {
      path: '/*',
      element: <PageNotFound className="mt-spacing-88" />,
    },
  ],
};

const router = createBrowserRouter([routes]);

enableMocking().then(() => {
  ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
      <MsalProvider instance={msalInstance}>
        <Provider store={store}>
          <ToastProvider>
            <RouterProvider router={router} />
          </ToastProvider>
        </Provider>
      </MsalProvider>
    </React.StrictMode>,
  );
});
